import { Injectable } from '@angular/core';
import * as lodash from 'lodash';
import moment from 'moment';
import { Papa } from 'ngx-papaparse';
import { map, switchMap, take } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import * as XLSX from 'xlsx';
import {
    COMPANIES_COLLECTION,
    ENCODING_READER_CSV_FILE,
    IMPORT_SETTINGS_COLLECTION,
    OPTINS_IMPORT_SETTINGS_ID,
    WEEKLY_WORKING_HOURS
} from '../../../../app.constants';
import { OptinsImportFileTypeEnum } from '../../../../shared/enums/optins-import-file-type.enum';
import { checkFileExtension } from '../../../../utils/file.utils';
import { AbstractImportationStrategy } from './abstract-importation-strategy';
import { FirestoreService } from 'src/app/core/services/firestore.service';
import { BookkeepingKeyService } from '../../bookkeeping-suggestions/bookkeeping-keys/services/bookkeeping-key.service';
import { AccountingSystemsEnum } from 'src/app/shared/enums/accounting-systems.enum';

@Injectable()
export class LodasImportationStrategy extends AbstractImportationStrategy {

    optins = [];

    public progressData = {
        startProcessFile: {
            ratio: 2.5
        },
        finishProcessFile: {
            ratio: 2.5
        },
        extractPersonalData: {
            ratio: 20
        },
        extractFinancialData: {
            ratio: 20
        },
        extractWageHistoryData: {
            ratio: 20
        },
        normalizeData: {
            ratio: 30
        }
    };


    constructor(private papa: Papa,
                private userService: UserService,
                private firestoreService: FirestoreService,
                private bookkeepingKeyService: BookkeepingKeyService,
    ) {
        super();
    }

    async import(mainFile: File, wageHistory: File, optins): Promise<any> {
        try {
            this.optins = optins;
            const fillDataList = await this.processFile(mainFile, OptinsImportFileTypeEnum.PERSONAL_DATA).then((data) => data);
            const fullWageHistoryList = await this.processFile(wageHistory, OptinsImportFileTypeEnum.WAGE_HISTORY_DATA)
                .then((data) => data);
            if (this.userService.isDebugImportFileMode) {
                await this.saveFilesInFirestoreForFutureDebug(
                    this.firestoreService,
                    mainFile,
                    this.userService.companyId
                );
                await this.saveFilesInFirestoreForFutureDebug(
                    this.firestoreService,
                    wageHistory,
                    this.userService.companyId
                );
            }
            return await this.extractOptinsFromImportedLists(fillDataList, fullWageHistoryList).then((data) => data);
        } catch (e) {
            throw new Error(e);
        }
    }

    private processFile(file: File, type: OptinsImportFileTypeEnum) {
        this.emitProgress(this.progressData.startProcessFile.ratio);
        if (checkFileExtension(file, ['.csv'])) {
            return this.processCSV(file);
        } else if (checkFileExtension(file, ['.txt'])) {
            return this.processTxt(file);
        } else {
            return this.processXLSX(file);
        }
    }

    private processTxt(file: File) {
        return new Promise((resolve, reject) => {
            try {
                const fileReader = new FileReader();
                fileReader.readAsText(file, ENCODING_READER_CSV_FILE);
                fileReader.onerror = (err) => {
                    reject(err);
                };
                fileReader.onload = (e) => {
                    const csv: any = fileReader.result;
                    const allTextLines = csv.split(/\r|\n|\r/);
                    const headers = allTextLines[0].split(/\n|\t|;/);
                    const result = [];
                    for (let i = 1; i < allTextLines.length; i++) {
                        const data = allTextLines[i].split(/\n|\t|;/);
                        if (data.length === headers.length) {
                            const obj = {};
                            for (let j = 0; j < headers.length; j++) {
                                const headerFormatted = headers[j].replace(new RegExp('"', 'g'), '');
                                obj[headerFormatted] = data[j].replace(new RegExp('"', 'g'), '');
                            }
                            result.push(obj);
                        }
                    }
                    this.emitProgress(this.progressData.finishProcessFile.ratio);
                    resolve(result);
                };
            } catch (e) {
                reject(e);
            }
        });
    }

    private processXLSX(file: File) {
        return new Promise((resolve, reject) => {
            try {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file);
                fileReader.onerror = (err) => {
                    reject(err);
                };
                fileReader.onload = (e) => {
                    const arrayBuffer: any = fileReader.result;
                    const data = new Uint8Array(arrayBuffer);
                    const arr = [];
                    for (let i = 0; i !== data.length; ++i) {
                        arr[i] = String.fromCharCode(data[i]);
                    }
                    const bstr = arr.join('');
                    const workbook = XLSX.read(bstr, {type: 'binary', cellDates: true});
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    this.emitProgress(this.progressData.finishProcessFile.ratio);
                    resolve(XLSX.utils.sheet_to_json(worksheet, {raw: true}));
                };
            } catch (e) {
                reject(e);
            }
        });
    }

    private processCSV(file: File) {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.readAsText(file, ENCODING_READER_CSV_FILE);
                reader.onerror = (err) => {
                    reject(err);
                };
                reader.onload = (event: any) => {
                    this.papa.parse(event.target.result, {
                        skipEmptyLines: true,
                        header: true,
                        encoding: ENCODING_READER_CSV_FILE,
                        complete: async (results) => {
                            this.emitProgress(this.progressData.finishProcessFile.ratio);
                            resolve(results.data);
                        }
                    });
                };
            } catch (e) {
                reject(e);
            }
        });
    }

    private extractOptinsFromImportedLists(data: any, wageHistoryData: any) {
        return new Promise((resolve, reject) => {
            this.service
                .getById(
                    OPTINS_IMPORT_SETTINGS_ID,
                    `${COMPANIES_COLLECTION}/${this.userService.loggedUser.companyId}/${IMPORT_SETTINGS_COLLECTION}`
                )
                .pipe(
                    take(1),
                    switchMap((fields) => {
                        return this.bookkeepingKeyService
                            .fetchAllKeys(AccountingSystemsEnum.LODAS)
                            .pipe(map((allKeys) => ({ allKeys, fields })));
                    })
                )
                .subscribe({
                    next: ({fields, allKeys}) => {
                        try {
                            this.emitProgress(
                                this.progressData.extractPersonalData.ratio
                            );
                            const optinsGeneralData =
                                this.extractPersonalDataByOptins(
                                    data,
                                    {
                                        ...fields.personalData,
                                        ...fields.financialData,
                                    },
                                    this.optins
                                );
                            this.emitProgress(this.progressData.extractFinancialData.ratio);
                            const fieldsWageHistory = {
                                registration: fields.personalData.registration,
                                billingMonth: fields.financialData.billingMonth,
                                amount: fields.financialData.amount,
                                wageTypeNumber:
                                    fields.financialData.wageTypeNumber,
                                masterWageTypeNumber:
                                    fields.financialData.masterWageTypeNumber,
                            };
                            const optinsWageHistoryData =
                                this.extractWageHistoryDataByImportedUsers(
                                    wageHistoryData,
                                    fieldsWageHistory,
                                    optinsGeneralData
                                );
                            const optinsPersonalDataHandled =
                                this.removeDuplicates(optinsGeneralData);
                            if (optinsWageHistoryData.length === 0) {
                                this.openDialogWageHistoryDataNotFound();
                                reject(
                                    'The wage history list cannot be empty.'
                                );
                            }
                            const result = this.normalizeData(
                                this.joinAllData(
                                    optinsPersonalDataHandled,
                                    optinsWageHistoryData
                                ),
                                allKeys,
                            );
                            this.emitProgress(
                                this.progressData.normalizeData.ratio
                            );
                            this.progress = 0;
                            resolve(result);
                        } catch (e) {
                            reject(e);
                        }
                    },
                    error: (error) => {
                        reject(error);
                    },
                });
        });
    }

    private removeDuplicates(optinsPersonalData: any[]) {
        return lodash.uniqBy(optinsPersonalData, 'registration');
    }

    private joinAllData(personalData: any[], wageHistoryData: any[]) {
        const result = [];
        if (personalData.length === 0) {
            this.emitProgress(this.progressData.normalizeData.ratio);
        } else {
            const progressJumps = (this.progressData.normalizeData.ratio / personalData.length);
            for (const optIn of personalData) {
                if (!optIn.wageData) {
                    optIn.wageData = [];
                }
                if (!optIn.wageHistoryData) {
                    optIn.wageHistoryData = [];
                }
                for (const wageHistory of wageHistoryData) {
                    if (wageHistory.registration === optIn.registration) {
                        delete wageHistory.registration;
                        optIn.wageHistoryData.push(wageHistory);
                    }
                }
                this.emitProgress(progressJumps);
                result.push(optIn);
            }
        }
        return result;
    }

    private extractPersonalDataByOptins(dataImported: any[], fields, optins: any[]) {
        let result = [];
        if (dataImported.length === 0) {
            this.emitProgress(this.progressData.extractPersonalData.ratio);
        } else {
            const progressJumps = (this.progressData.extractPersonalData.ratio / dataImported.length);

            for (const importedUser of dataImported) {
                const user = this.buildObject(importedUser, fields);
                const foundOptin = this.findOptinUser(user, optins);
                if (foundOptin) {
                    user.lastModifiedAt = foundOptin.lastModifiedAt;
                    result.push(user);
                }
                this.emitProgress(progressJumps);
            }

            const employeeGroupedByRegistration = lodash.groupBy(result, 'registration');
            result = Object.keys(employeeGroupedByRegistration).map(registration => {
                const employees = employeeGroupedByRegistration[registration];
                const maxDateOfWageList = new Date(
                    Math.max(
                        ...employees.map((e) => {
                            return moment(e.billingMonth, 'YYYY-MM').toDate().getTime();
                        })
                    )
                );
                const maxBillingMonth = new Date(maxDateOfWageList);
                return employees.filter(e => moment(e.billingMonth, 'YYYY-MM').isSame(maxBillingMonth));
            }).flat();
        }
        return result;
    }

    private extractWageHistoryDataByImportedUsers(wageHistoryData: any[], fields, importedUsers: any[]) {
        const result = [];
        if (wageHistoryData.length === 0) {
            this.emitProgress(this.progressData.extractWageHistoryData.ratio);
        } else {
            const progressJumps = (this.progressData.extractWageHistoryData.ratio / wageHistoryData.length);
            for (const importedData of wageHistoryData) {
                const data: any = this.buildObject(importedData, fields);
                if (this.matchOptinFinancialData(data, importedUsers)) {
                    result.push({
                        registration: data.registration,
                        billingMonth: data.billingMonth.replace('/', '-'),
                        amount: data.amount,
                        wageTypeNumber: data.wageTypeNumber || data.serviceKey,
                        paymentFrequence: data.paymentFrequence,
                    });
                }
                this.emitProgress(progressJumps);
            }
            return result;
        }
    }

    private normalizeData(items: any[], allKeys: { keys: any }) {
        for (const item of items) {
            if (item.privateHealthInsurance) {
                item.privateHealthInsurance = ['ja', 'yes'].includes(item.privateHealthInsurance.trim().toLowerCase());
            }
            item.addressStreetRaw = item.addressStreet;
            const addressStreetSplit = item.addressStreet.split(' ');
            if (addressStreetSplit.length > 1) {
                let newAddressStreet = '';
                for (let i = 0; i < addressStreetSplit.length - 1; i++) {
                    newAddressStreet += ` ${addressStreetSplit[i]}`;
                }
                item.addressStreet = newAddressStreet;
                item.addressNumber = addressStreetSplit[addressStreetSplit.length - 1];
            }
            item.annualTaxAllowance = (item.annualExemption || 0);
            item.healthInsuranceBasicContribution =
                (item.privateHealthInsuranceTotalContribution || 0) +
                (item.privateCareInsuranceTotalContribution || 0);
            item.religion = ![
                null,
                undefined,
                '--',
                '- -',
                'vd',
                '',
                ' ',
                'konfessionslos, keine Kirchensteuerberechnung',
                'Konfessionslos, keine Kirchensteuerberechnung',
                'konfessionslos',
                'Konfessionslos'
            ].includes(item.religion);

            item.gender = this.getGender(item.gender);
            item.contributionGroupKey = item.contributionGroupKey?.toString().replace(/(\d{1})(?=\d)/g, '$1-');

            // Lodas - it should be the opposite of what we import - !Zuschlag PV
            item.careInsuranceContribution = !item.careInsuranceContribution;

            if (!item.taxFactor) {
                item.taxFactor = 1;
            }

            if (!item.children) {
                item.children = 0;
            }

            if (!item.weeklyWorkingHours && item.workingTimeWeekOperation) {
                item.weeklyWorkingHours = item.workingTimeWeekOperation;
            }

            if (item.weeklyWorkingHoursAlternative) {
                item.weeklyWorkingHours = item.weeklyWorkingHoursAlternative;
            }

            if (!item.weeklyWorkingHours || item.weeklyWorkingHours === 0) {
                item.weeklyWorkingHours = WEEKLY_WORKING_HOURS;
            }

            if (item.socialContributionRegion && item.socialContributionRegion?.endsWith('MAD')) {
                item.socialContributionRegion = 1;
            }
            item.type = 'lodas';
            item.registration = item.registration?.toString();

            if (item.wageHistoryData) {
                const maxDateOfWageList = new Date(
                    Math.max(
                        ...item.wageHistoryData.map((e) => {
                            return moment(e.billingMonth, 'YYYY-MM').toDate().getTime();
                        })
                    )
                );
                const maxBillingMonth = new Date(maxDateOfWageList);
                item.billingMonth = moment(maxBillingMonth).format('YYYY-MM');

                const basicWageKeys: Array<number> = allKeys.keys
                    .find((e) => e.id === 'basic-wage')
                    .code.map(Number);

                const bkvKeys: Array<number> = allKeys.keys
                    .find((e) => e.id === 'bkv')
                    .code.map(Number); 

                item.wageData = [];
                for (let index = 0; index < item.wageHistoryData.length; index++) {
                    const wageHistory = item.wageHistoryData[index];
                    if (
                        wageHistory.billingMonth === item.billingMonth &&
                        (
                            basicWageKeys.includes(Number(wageHistory.wageTypeNumber)) || 
                            bkvKeys.includes( Number(wageHistory.wageTypeNumber))
                        )
                    ) {
                        item.wageHistoryData[index] = null;
                        item.wageData.push({
                            registration: wageHistory.registration,
                            billingMonth: wageHistory.billingMonth,
                            amount: wageHistory.amount,
                            serviceKey: wageHistory.wageTypeNumber,
                            paymentFrequence: wageHistory.paymentFrequence,
                            isFromWageHistory: true,
                        });
                    } else if (wageHistory.paymentFrequence) {
                        wageHistory.onePayment = wageHistory.paymentFrequence.localeCompare(
                            'jährlich',
                            'de',
                            { sensitivity: 'base' }
                        ) === 0;
                    }
                }
                // Only not null elements
                item.wageHistoryData = item.wageHistoryData.filter(e => !!e);
            }

            delete item.grossSalary;
            delete item.annualExemption;
            delete item.allowanceMonthly;
            delete item.privKVTotalContribution;
            delete item.privPVTotalContribution;
            delete item.privKVAgZuschub;
            delete item.privPVAgZuschub;
            delete item.amount;
            delete item.serviceKey;
            delete item.costCentre;
        }
        return items;
    }
}
